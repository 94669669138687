<template>
  <Tab />
  <NavigationMainDesktop id="jump-navigation" class="max-sm:hidden" />
  <NavigationMainMobile id="jump-navigation" class="sm:hidden" />
  <div
    class="flex min-h-screen w-full flex-col justify-between pt-navbar-height @container-normal @container/main"
  >
    <slot />
    <DevOnly>
      <div class="fixed bottom-20 left-10 z-50 flex flex-col gap-3">
        <Breaky />
      </div>
    </DevOnly>
    <NavigationFooter />
  </div>
</template>
